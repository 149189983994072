import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import ContentFrame from '../content_frame';
import DefaultSection from '../../utils/default_section';
import * as routes from '../../constants';
import {COACH_ACCESS_LEVEL, DEFAULT_UNIT_TYPE} from '../../constants';
import {getModel, postModel, patchModel, setUrlParameters} from '../../utils/functions';
import './student_manage.scss';
import HorizontalMenu from './horizontal_menu';
import * as paths from './constants';
import Overview from './overview';
import ContractList from './contract/contract_list';
import ContractAdd from './contract/contract_add';
import ContractEdit from './contract/contract_edit';
import StudentGeneralData from './student_general_data';
import StudentAddressData from './student_address_data';
import FinancialList from './financial/financial_list';
import Disc from './disc';
import PhysicalEvaluation from './physical_evaluation';
import PhysicalEvaluationAdd from './physical_evaluation_add';
import PersonalTraining from './personal_training';
import FoodPrescription from './food_prescription';
import SupportTicketList from './support/support_ticket_list';
import SupportTicketAdd from './support/support_ticket_add';
import ImagePicker from '../../components/image_picker';
import StudentAccessControl from './access_control/student_access_control';
import * as permissions from '../../permissions';


class StudentManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: {},
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      loading: true,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    this.reloadStudent();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadStudent(loadOverview=false) {
    this.setState({
      loading: true
    });

    try {
      const student = await getModel(setUrlParameters(`${routes.STUDENT_GET_API}${this.props.match.params.studentId}`, {load_avatar_image: true}));

      if(student) {
        this.setState({
          student: {...this.state.student, ...student},
          loading: false
        });
      }
      else {
        this.props.history.replace(routes.STUDENT_LIST_PATH);
      }
    }
    catch(errors) {
      this.props.history.replace(routes.STUDENT_LIST_PATH);
    }

    if(loadOverview) {
      this.props.history.replace(`${routes.STUDENT_MANAGE_PATH}${this.state.student.id}`);
    }
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const newData = {...this.state.student, [name]: value};

    this.setState({
      student: newData
    });
  }

  inputsAreValid() {
    return this.state.student.name.length > 0 &&
           this.state.student.email.match(/.+@.+\..+/) != null &&
           this.state.student.phone.length > 0;
  }

  async saveData() {
    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    let studentData = {...this.state.student};

    try {
      await patchModel(`${routes.STUDENT_PATCH_API}${this.props.match.params.studentId}`, studentData);
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 103) {
            for(let parameter of error.parameters) {
              if(parameter.name === 'email') {
                warningMessages.push('E-mail já cadastrado');
                highlights.push('email');
              }
            }
          }
          else if(error.code === 209) {
            warningMessages.push('Sessão do usuário expirada');
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.replace(routes.STUDENT_LIST_PATH);
  }

  async onUpdateUserImage(dataURL) {
    this.setState({
      highlights: [],
      showWarningMessage: false,
    });

    let data = {file: dataURL};

    try {
      const response = await postModel(routes.USER_IMAGE_POST_API.replace('{id}', this.props.match.params.studentId), data, true);

      if(response) {
        this.setState({
          student: {...this.state.student, avatar_image_url: response.image_url}
        });
      }
    }
    catch(errors) {
      let warningMessages = [];
      let highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          if(error.code === 103) {
            // for(let parameter of error.parameters) {
            //   if(parameter.name === 'email') {
            //     warningMessages.push('E-mail já cadastrado');
            //     highlights.push('email');
            //   }
            // }
          }
          else if(error.code === 209) {
            warningMessages.push('Sessão do usuário expirada');
          }
        }
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
      });

      return false;
    }

    return true;
  }

  getViewName(pathName) {
    let viewName = '';

    if(pathName.startsWith(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.CONTRACT_EDIT_PATH}`)) {
      return 'Contratos';
    }

    switch (pathName) {
      case `${routes.STUDENT_MANAGE_PATH}${this.state.student.id}`:
        viewName = 'Visão geral';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.CONTRACT_PATH}`:
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.CONTRACT_ADD_PATH}`:
        viewName = 'Contratos';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.FINANCIAL_PATH}`:
        viewName = 'Financeiro';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PHYSICAL_AVALIATION_PATH}`:
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PHYSICAL_EVALUATION_ADD_PATH}`:
        viewName = 'Avaliação física';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.DISC_PATH}`:
        viewName = 'DISC';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PERSONAL_TRAINING_PATH}`:
        viewName = 'Personal training';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.FOOD_PRESCRIPTION_PATH}`:
        viewName = 'Nutrição';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ADD_SUPPORT_TICKET_PATH}`:
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.SUPPORT_PATH}`:
        viewName = 'Suporte';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ADDRESS_PATH}`:
        viewName = 'Endereço';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}`:
        viewName = 'Dados gerais';
        break;
      case `${routes.INCOME_ADD_PATH}?student_id=${this.state.student.id}`:
        viewName = 'Adicionar pagamento';
        break;
      case `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ACCESS_CONTROL_PAGE}`:
        viewName = 'Acesso';
        break;
      default:
    }

    return viewName;
  }

  updateContracs(contracs) {
    const student = {...this.state.student, contracts: contracs};

    this.setState({student});
  }

  getMenuIcons() {
    const icons = [];

    if(this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID)) {
      icons.push({
        to: `${routes.STUDENT_MANAGE_PATH}${this.state.student.id}`,
        icon: <i className="fas fa-eye"></i>,
        text: this.getViewName(`${routes.STUDENT_MANAGE_PATH}${this.state.student.id}`),
      });

      if(this.props.userPermissionIds.includes(permissions.VIEW_CONTRACT_PERMISSION_ID)) {
        icons.push({
          to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.CONTRACT_PATH}`,
          icon: <i className="fas fa-file-invoice-dollar"></i>,
          text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.CONTRACT_PATH}`),
        });
      }

      if(this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID)) {
        icons.push({
          to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.FINANCIAL_PATH}`,
          icon: <i className="fas fa-money-bill-alt"></i>,
          text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.FINANCIAL_PATH}`),
        });
      }

      if(this.props.userPermissionIds.includes(permissions.LIST_PHYSICAL_EVALUATION_PERMISSION_ID) ||
         this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
        icons.push({
          to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PHYSICAL_AVALIATION_PATH}`,
          icon: <i className="fas fa-weight"></i>,
          text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PHYSICAL_AVALIATION_PATH}`),
        });
      }

      if(this.state.student.disc) {
        icons.push({
          to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.DISC_PATH}`,
          icon: <i className="fas fa-brain"></i>,
          text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.DISC_PATH}`),
        });
      }

      if(this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_DATA_PERMISSION_ID)) {
        icons.push({
          to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ADDRESS_PATH}`,
          icon: <i className="fas fa-truck"></i>,
          text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ADDRESS_PATH}`),
        });
      }

      if(this.props.userPermissionIds.includes(permissions.ADD_STUDENT_FINANCIAL_ENTRIES_PERMISSION_ID) && this.props.userAccessLevel === COACH_ACCESS_LEVEL) {
        icons.push({
          to: `${routes.INCOME_ADD_PATH}?student_id=${this.state.student.id}`,
          icon: <i className="fas fa-money-bill-alt"></i>,
          text: this.getViewName(`${routes.INCOME_ADD_PATH}?student_id=${this.state.student.id}`),
        });
      }

      icons.push({
        to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}`,
        icon: <i className="far fa-edit"></i>,
        text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}`),
      });

      if(this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_PERIOD_PERMISSION)) {
        icons.push({
          to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PERSONAL_TRAINING_PATH}`,
          icon: <i className="fas fa-dumbbell"></i>,
          text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PERSONAL_TRAINING_PATH}`),
        });
      }

      if(this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)) {
        icons.push({
          to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.FOOD_PRESCRIPTION_PATH}`,
          icon: <i className="fas fa-utensils"></i>,
          text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.FOOD_PRESCRIPTION_PATH}`),
        });
      }

      if(this.props.userPermissionIds.includes(permissions.MANAGE_CUSTOMER_SUPPORT_PERMISSION_ID) && this.isDefaultUnit()) {
        icons.push({
          to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.SUPPORT_PATH}`,
          icon: <i className="fa-solid fa-headset"></i>,
          text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.SUPPORT_PATH}`),
        });
      }

      if(this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID) && this.isDefaultUnit()) {
        icons.push({
          to: `${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ACCESS_CONTROL_PAGE}`,
          icon: <i className="fa-solid fa-key"></i>,
          text: this.getViewName(`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ACCESS_CONTROL_PAGE}`),
        });
      }
    }

    return icons;
  }

  getRoutes() {
    const routeElements = [];

    if(this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_BASIC_DATA_PERMISSION_ID)) {
      if(this.props.userPermissionIds.includes(permissions.VIEW_CONTRACT_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="student_manage_route:contract_list"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.CONTRACT_PATH}`}
            render={(props) => (
              <ContractList
                key={`student_manage_${this.state.student.id}_contract_list`}
                {...props}
                student={this.state.student}
                onUpdateContracts={(contracts) => this.updateContracs(contracts)}
                userPermissionIds={this.props.userPermissionIds}
                userAccessLevel={this.props.userAccessLevel}
              />
            )}
          />
        );
      }

      if(this.props.userPermissionIds.includes(permissions.ADD_CONTRACT_BASIC_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="student_manage_route:contract_add"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.CONTRACT_ADD_PATH}`}
            render={(props) => (
              <ContractAdd
                key={`student_manage_${this.state.student.id}_contract_add`}
                {...props}
                student={this.state.student}
                userPermissionIds={this.props.userPermissionIds}
                userAccessLevel={this.props.userAccessLevel}
                unit_type_id={this.props.unit_type_id}
              />
            )}
          />
        );
      }

      if(this.props.userPermissionIds.includes(permissions.ADD_CONTRACT_BASIC_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="student_manage_route:contract_edit"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.CONTRACT_EDIT_PATH}:contractId`}
            render={(props) => (
              <ContractEdit
                key={`student_manage_${this.state.student.id}_contract_edit`}
                {...props}
                student={this.state.student}
                userPermissionIds={this.props.userPermissionIds}
                userAccessLevel={this.props.userAccessLevel}
                unit_type_id={this.props.unit_type_id}
              />
            )}
          />
        );
      }

      routeElements.push(
        <Route
          key="student_manage_route:overview"
          path={`${routes.STUDENT_MANAGE_PATH}${this.state.student.id}`}
          render={(props) => (
            <Overview
              key={`student_manage_${this.state.student.id}_overview`}
              {...props}
              userPermissionIds={this.props.userPermissionIds}
              userAccessLevel={this.props.userAccessLevel}
              student={this.state.student}
              unit_type_id={this.props.unit_type_id}
            />
          )}
        />
      );

      if(this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="student_manage_route:financial_entries_list"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.FINANCIAL_PATH}`}
            render={(props) => (
              <FinancialList
                key={`student_manage_${this.state.student.id}_financial`}
                {...props}
                student={this.state.student}
              />
            )}
          />
        );
      }

      if(this.props.userPermissionIds.includes(permissions.VIEW_PHYSICAL_EVALUATION_DATA_PERMISSION_ID) ||
         this.props.userPermissionIds.includes(permissions.LIST_PHYSICAL_EVALUATION_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="student_manage_route:physical_evaluation_overview"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PHYSICAL_AVALIATION_PATH}`}
            render={(props) => (
              <PhysicalEvaluation
                key={`student_manage_${this.state.student.id}_physical_evaluation`}
                {...props}
                student={this.state.student}
                onUpdateStatus={() => this.reloadStudent()}
                userPermissionIds={this.props.userPermissionIds}
                userAccessLevel={this.props.userAccessLevel}
                unit_type_id={this.props.unit_type_id}
              />
            )}
          />
        );
      }

      if(this.props.userPermissionIds.includes(permissions.ADD_PHYSICAL_EVALUATION_DATA_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="student_manage_route:physical_evaluation_add"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PHYSICAL_EVALUATION_ADD_PATH}`}
            render={(props) => (
              <PhysicalEvaluationAdd
                key={`student_manage_${this.state.student.id}_physical_evaluation_add`}
                {...props}
                onUpdateStatus={() => this.reloadStudent()}
                student={this.state.student}
                bluetoothDevices={this.props.bluetoothDevices}
                userPermissionIds={this.props.userPermissionIds}
              />
            )}
          />
        );
      }

      routeElements.push(
        <Route
          key="student_manage_route:disc_overview"
          path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.DISC_PATH}`}
          render={(props) => (
            <Disc
              key={`student_manage_${this.state.student.id}_disc`}
              {...props}
              student={this.state.student}
            />
          )}
        />
      );

      if(this.props.userPermissionIds.includes(permissions.VIEW_PERSONAL_TRAINING_PERIOD_PERMISSION)) {
        routeElements.push(
          <Route
            key="student_manage_route:personal_training"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.PERSONAL_TRAINING_PATH}`}
            render={(props) => (
              <PersonalTraining
                key={`student_manage_${this.state.student.id}_personal_training`}
                {...props}
                student={this.state.student}
                userPermissionIds={this.props.userPermissionIds}
                unit_type_id={this.props.unit_type_id}
              />
            )}
          />
        );
      }

      if(this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_FOOD_PRESCRIPTION_PERMISSION)) {
        routeElements.push(
          <Route
            key="student_manage_route:food_prescription"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.FOOD_PRESCRIPTION_PATH}`}
            render={(props) => (
              <FoodPrescription
                key={`student_manage_${this.state.student.id}_food_prescription`}
                {...props}
                student={this.state.student}
                userPermissionIds={this.props.userPermissionIds}
              />
            )}
          />
        );
      }

      if(this.props.userPermissionIds.includes(permissions.MANAGE_CUSTOMER_SUPPORT_PERMISSION_ID) && this.isDefaultUnit()) {
        routeElements.push(
          <Route
            key="student_manage_route:support_add"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ADD_SUPPORT_TICKET_PATH}`}
            render={(props) => (
              <SupportTicketAdd
                key={`student_manage_${this.state.student.id}_support_add`}
                {...props}
                student={this.state.student}
                userPermissionIds={this.props.userPermissionIds}
                unit_type_id={this.props.unit_type_id}
              />
            )}
          />
        );

        routeElements.push(
          <Route
            key="student_manage_route:support"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.SUPPORT_PATH}`}
            render={(props) => (
              <SupportTicketList
                key={`student_manage_${this.state.student.id}_support`}
                {...props}
                student={this.state.student}
                userPermissionIds={this.props.userPermissionIds}
              />
            )}
          />
        );
      }

      if(this.props.userPermissionIds.includes(permissions.MANAGE_STUDENT_ACCESS_CONTROL_CREDENTIALS_PERMISSION_ID) && this.isDefaultUnit()) {
        routeElements.push(
          <Route
            key="student_manage_route:access_control"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ACCESS_CONTROL_PAGE}`}
            render={(props) => (
              <StudentAccessControl
                key={`student_manage_${this.state.student.id}_access_control`}
                {...props}
                student={this.state.student}
                userPermissionIds={this.props.userPermissionIds}
                unit_type_id={this.props.unit_type_id}
              />
            )}
          />
        );
      }

      if(this.props.userPermissionIds.includes(permissions.VIEW_STUDENT_DATA_PERMISSION_ID)) {
        routeElements.push(
          <Route
            key="student_manage_route:address_edit"
            path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}${paths.ADDRESS_PATH}`}
            render={(props) => (
              <StudentAddressData
                key={`student_manage_${this.state.student.id}_address_data`}
                {...props}
                student={this.state.student}
                onSave={() => this.reloadStudent(true)}
                onCancelPath={`${routes.STUDENT_MANAGE_PATH}${this.state.student.id}`}
              />
            )}
          />
        );
      }

      routeElements.push(
        <Route
          key="student_manage_route:general_data_edit"
          path={`${routes.STUDENT_EDIT_PATH}${this.state.student.id}`}
          render={(props) => (
            <StudentGeneralData
              key={`student_manage_${this.state.student.id}_general_data`}
              {...props}
              userPermissionIds={this.props.userPermissionIds}
              student={this.state.student}
              onSave={() => this.reloadStudent(true)}
              onCancelPath={`${routes.STUDENT_MANAGE_PATH}${this.state.student.id}`}
              mayTriggerEmailChange={this.props.userPermissionIds.includes(permissions.TRIGGER_USER_EMAIL_CHANGE_PERMISSION_ID)}
              mayChangeEmail={this.props.userPermissionIds.includes(permissions.CHANGE_USER_EMAIL_PERMISSION_ID)}
            />
          )}
        />
      );
    }

    return routeElements;
  }

  render() {
    return (
      <ContentFrame
        location={this.props.location}
        headerHistory={[
          {
            path: routes.DESKTOP_PATH,
            text: "Área de trabalho"
          },
          {
            path: routes.STUDENT_LIST_PATH,
            text: "Listar alunos"
          },
          {
            path: this.props.location.pathname,
            text: "Gerenciar aluno"
          },
        ]}
        // titleIcon={<i className="fas fa-user-cog"></i>}
        title={
          <div className="student-manage__title-wrapper">

            <h2 className="student-manage__title__text">

              <i className="fas fa-user-cog student-manage__title__icon"></i>
              Gerenciar aluno:
              {(this.state.screenWidth <= 360 || this.state.screenWidth > 600) ? ' ' : (<br />)}<span className="student-manage__title-student-name">{this.state.student.name}</span>

            </h2>

            {(this.isDefaultUnit() && this.props.userPermissionIds.includes(permissions.UPDATE_USER_IMAGE_PERMISSION_ID)) &&
              <ImagePicker
                className="student-manage__image-picker"
                currentImage={this.state.student.avatar_image_url}
                maxWidth={500}
                maxHeight={500}
                onPictureSelected={(dataURL) => this.onUpdateUserImage(dataURL)}
                aspectRatio={1}
                userCircularMask={true}
              />
            }

          </div>
        }
        loading={this.state.loading}
      >

        <HorizontalMenu
          links={this.getMenuIcons()}
          location={this.props.location}
        />

        <DefaultSection
          className="student-manage__content-container"
          title={this.getViewName(this.props.location.pathname)}
        >

          <Switch>

            {this.getRoutes()}

            <Redirect from="/" to={`${routes.STUDENT_MANAGE_PATH}${this.state.student.id}`} />

          </Switch>

        </DefaultSection>

      </ContentFrame>
    );
  }
}

export default StudentManage;
