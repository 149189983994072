import React from 'react';
import ModelTable, {Property} from '../../utils/model_table';
import {DefaultSubSectionTitle, HorizontalRule} from '../../utils/default_section';
import './overview.scss';
import {getModels, getModel, postModel, getPhoneText, getAsLocalDateString, getAsLocalDatetime, setUrlParameters, getAsLocalDate, getLocalDateIsoString} from '../../utils/functions';
import {CONTRACT_EXPIRATION_ALERT_RANGE,
        PAYMENT_ALERT_RANGE,
        PHYSICAL_REVALUATION_THRESHOLD,
        PHYSICAL_REVALUATION_ALERT_RANGE,
        NOTIFICATION_TEXT_MAP,
        NOTIFICATION_UNREAD_THRESHOLD,
        NOTIFICATION_UNREAD_ALERT_RANGE,
        STUDENT_RECENT_TRAINING_PERIOD,
        STUDENT_LAST_TRAINING_ALERT_RANGE,
        STUDENT_LAST_TRAINING_RANGE,
        DEFAULT_UNKNOWN_ERROR_MESSAGE,
        ACCESS_CONTROL_RULE_BLOCKED,
        DEFAULT_UNIT_TYPE} from '../../constants';
import { PoseGroup } from 'react-pose';
import {FadeContainer} from '../../utils/pose_containers';
import ConfirmationWindow from '../confirmation_window';
import PreLoader from '../../utils/preloader';
import * as routes from '../../constants';
import * as permissions from '../../permissions';
import SimpleBarGraph, {DateBarPoint} from '../../graphs/simple_bar_graph';


class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      notifications: [],
      training_data: [],
      notificationStatusData: null,
      sendValidationEmail: false,
      validationEmailSent: false,
      access_rule: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      loadingData: true,
      screenWidth: window.innerWidth,
    };
  }

  isDefaultUnit() {
    return this.props.unit_type_id === DEFAULT_UNIT_TYPE;
  }

  async getTransactions() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_FINANCIAL_ENTRIES_PERMISSION_ID)) {
      return await getModels(`${routes.FINANCIAL_TRANSACTIONS_GET_API}?student_id=${this.props.student.id}&pending=true`);
    }

    return [];
  }

  async getNotifications() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_USER_NOTIFICATION_PERMISSION_ID)) {
      return await getModels(`${routes.NOTIFICATIONS_GET_API}?student_id=${this.props.student.id}`);
    }

    return [];
  }

  async getLastNotificationStatusData(notification_id) {
    if(this.props.userPermissionIds.includes(permissions.VIEW_USER_NOTIFICATION_STATUS_UPDATE_DATA_PERMISSION)) {
      this.setState({
        confirmInProgress: true
      });

      let statusData;
      try {
        statusData = await getModel(routes.NOTIFICATION_GET_LAST_STATUS_UPDATE_API.replace('{notification_id}', notification_id));
      }
      catch(errors) {
        console.log(errors);
        this.setState({
          confirmInProgress: false,
          notificationStatusData: null,
          confirmFailed: true,
          confirmFailDescription: DEFAULT_UNKNOWN_ERROR_MESSAGE
        });

        return;
      }

      this.setState({
        confirmInProgress: false,
        // notificationStatusData: {
        //   'delivery-status': {
        //     'attempt-no': 1,
        //     'certificate-verified': true,
        //     'code': 250,
        //     'description': "",
        //     'message': "5.7.1 Unfortunately, messages from [209.61.151.248] weren't sent. Please contact your Internet service provider since part of their network is on our block list (S3140). You can also refer your provider to http://mail.live.com/mail/troubleshooting.aspx#errors. [VI1EUR06FT016.eop-eur06.prod.protection.outlook.com]",
        //     'mx-host': "gmail-smtp-in.l.google.com",
        //     'session-seconds': 43.33345580101013,
        //     'tls': true,
        //     'utf8': true
        //   },
        //   'date': "2021-06-27T23:28:55.179351",
        //   'event': "delivered",
        //   'reason': "espblock",
        //   'severity': "permanent"
        // }
        notificationStatusData: statusData
      });
    }

    return null;
  }

  async getTrainingData() {
    if(this.props.userPermissionIds.includes(permissions.VIEW_TRAINING_DATA_PERMISSION_ID)) {
      let url = `${routes.TRAINING_DATA_ENTRIES_GET_API}?student_id=${this.props.student.id}`;

      return await getModels(url);
    }

    return [];
  }

  async getAccessControlRule() {
    if (this.isDefaultUnit()) {
      return await getModels(routes.USER_ACCESS_CONTROL_ACCESS_RULE_GET_API.replace('{id}', this.props.student.id));
    }

    return null;
  }

  async componentDidMount() {
    this.reloadData();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadData() {
    this.setState({
      loadingData: true
    });

    const update = {loadingData: false};

    let transactions = this.getTransactions();
    let notifications = this.getNotifications();
    let training_data = this.getTrainingData();
    let access_rule = this.getAccessControlRule();

    transactions = await transactions;
    if(transactions) {
      update.transactions = transactions;
    }

    notifications = await notifications;
    if(notifications) {
      update.notifications = notifications;
    }

    training_data = await training_data;
    if(training_data) {
      update.training_data = training_data;
    }

    access_rule = await access_rule;
    if(access_rule) {
      update.access_rule = access_rule.access_rule;
    }

    this.setState(update);
  }

  getCreatedAtText(entry) {
    return getAsLocalDatetime(entry.created_at, false).toLocaleString();
  }

  getUpdatedAtText(entry) {
    return getAsLocalDatetime(entry.updated_at, false).toLocaleString();
  }

  getStatusFilter(entry) {
    return NOTIFICATION_TEXT_MAP[entry.status];
  }

  geStatusText(entry) {
    let iconClass = null;
    let allowDetails = false;

    switch (entry.status) {
      case 'PROCESSING':
        iconClass = 'database';
        break;
      case 'ACCEPTED':
        iconClass = 'database';
        break;
      case 'DELIVERED':
        iconClass = 'database';
        break;
      case 'PERMANENT_FAILURE':
        iconClass = 'exclamation';
        allowDetails = true;
        break;
      case 'TEMPORARY_FAILURE':
        iconClass = 'exclamation';
        allowDetails = true;
        break;
      case 'OPENED':
        iconClass = 'check';
        break;
      case 'CLICKED':
        iconClass = 'check';
        break;
      case 'UNSUBSCRIBED':
        iconClass = 'exclamation';
        allowDetails = true;
        break;
      case 'COMPLAINED':
        iconClass = 'exclamation';
        allowDetails = true;
        break;
      case 'STORED':
        iconClass = 'database';
        break;
      case 'REJECTED':
        iconClass = 'exclamation';
        allowDetails = true;
        break;
      case 'UNKNOWN':
        iconClass = 'exclamation';
        allowDetails = true;
        break;
      default:
    }

    if(this.props.userPermissionIds.includes(permissions.VIEW_USER_NOTIFICATION_STATUS_UPDATE_DATA_PERMISSION) && allowDetails) {
      return (
        <button
          className="overview__notifications__cell-wrapper--button"
          onClick={() => {
            this.getLastNotificationStatusData(entry.id)
          }}
        >

          <p className={`overview__notifications__status-text--${entry.status.toLowerCase()}`}>

            {iconClass ?
              <i className={`fas fa-${iconClass} overview__notifications__status-text__icon`}></i>:
              null}
            {NOTIFICATION_TEXT_MAP[entry.status]}

          </p>

        </button>
      );
    }

    return (
      <div className="overview__notifications__cell-wrapper--centered">

        <p className={`overview__notifications__status-text--${entry.status.toLowerCase()}`}>

          {iconClass ?
            <i className={`fas fa-${iconClass} overview__notifications__status-text__icon`}></i>:
            null}
          {NOTIFICATION_TEXT_MAP[entry.status]}

        </p>

      </div>
    );
  }

  getActivityProperties() {
    const properties = [
      Property('created_at', 'Data', <i className="fas fa-calendar-alt"></i>, {getDataText: this.getCreatedAtText, getFilterText: this.getCreatedAtText}),
      Property('name', 'Atividade', <i className="fas fa-tag"></i>)
    ];

    return properties;
  }

  getNotificationProperties() {
    const properties = [
      Property('created_at', 'Enviado em', <i className="fas fa-calendar-alt"></i>, {getDataText: this.getCreatedAtText, getFilterText: this.getCreatedAtText}),
      Property('content_identifier', 'Tipo', <i className="fas fa-tag"></i>),
      Property('status', 'Situação', <i className="fas fa-thermometer-half"></i>, {
        getDataText: (entry) => this.geStatusText(entry),
        getFilterText: this.getStatusFilter}),
      Property('updated_at', 'Atualizado em', <i className="fas fa-calendar-alt"></i>, {getDataText: this.getUpdatedAtText, getFilterText: this.getUpdatedAtText}),
    ];

    return properties;
  }

  getStatus() {
    const statusList = [];

    const today = new Date();
    const todayIsoDate = today.toISOString().slice(0, 10);

    if(this.state.transactions.length) {
      const transactions = this.state.transactions;

      let financialNotification;

      for(let transaction of transactions) {
        if(transaction.effective_date < todayIsoDate) {
          statusList.push(
            <div key="overview_status_financial_late" className="overview__status--inativo">

              <i className="fas fa-exclamation overview__status__icon"></i>
              <span className="overview__status__text">{transaction.is_expense ? 'Dívida atrasada' : 'Pagamento atrasado'}</span>

            </div>
          );

          financialNotification = null;

          break;
        }

        if(financialNotification && financialNotification.effective_date < transaction.effective_date) {
          continue;
        }

        financialNotification = transaction;
      }

      if(financialNotification) {
        let daysToExpire

        if(financialNotification.effective_date === todayIsoDate) {
          daysToExpire = 0;
        }
        else {
          const date = getAsLocalDate(financialNotification.effective_date);
          const timeDiff = Math.abs(date.getTime() - today.getTime());
          daysToExpire = Math.ceil(timeDiff / (1000 * 3600 * 24));
        }

        if(daysToExpire < PAYMENT_ALERT_RANGE) {
          const name = financialNotification.is_expense ? 'Dívida' : 'Pagamento';
          let content;

          if(daysToExpire === 0) {
            content = `${name} vence HOJE`;
          }
          else {
            content = (
              <React.Fragment>

                {name}
                {' '}vence em
                {' '}<strong className="overview__status__date-text">{daysToExpire}</strong>
                {' '}dia{daysToExpire > 1 ? 's' : ''}

              </React.Fragment>
            );
          }

          statusList.push(
            <div key="overview_status_financial_alert" className="overview__status--alerta">

              <i className="fas fa-exclamation overview__status__icon"></i>
              <span className="overview__status__text">

                {content}

              </span>

            </div>
          );
        }
      }
    }

    if(this.props.student.contracts.length) {
      const contracts = this.props.student.contracts;

      if(contracts.some((contract) => !contract.accepted && contract.expires_at.slice(0, 10) > todayIsoDate)) {
        statusList.push(
          <div key="overview_status_contract_not_accepted" className="overview__status--alerta">

            <i className="fas fa-exclamation overview__status__icon"></i>
            <span className="overview__status__text">Contrato não aceito</span>

          </div>
        );
      }
      else {
        const filteredContracts = contracts.filter((contract) => contract.accepted && !contract.canceled && contract.expires_at.slice(0, 10) >= todayIsoDate);

        if(filteredContracts.length > 0) {
          const contractToExpire = filteredContracts.sort((contract) => contract.expires_at)[0];

          statusList.push(
            <div key="overview_status_contract_expired" className={`overview__status${contractToExpire.days_to_expire < CONTRACT_EXPIRATION_ALERT_RANGE ? '--alerta' : '--normal'}`}>

              <i className="fas fa-exclamation overview__status__icon"></i>
              <span className="overview__status__text">

                Contrato expira dia
                {' '}<strong className="overview__status__date-text">{getAsLocalDateString(contractToExpire.expires_at)}</strong>

              </span>

            </div>
          );
        }
        else {
          const expiredContracts = contracts.filter((contract) => contract.accepted  && !contract.canceled && contract.expires_at.slice(0, 10) < todayIsoDate);

          if(expiredContracts.length > 0) {
            const lastContract = expiredContracts.sort((contract) => contract.expires_at)[expiredContracts.length-1];

            statusList.push(
              <div key="overview_status_contract_expired" className="overview__status--alerta">

                <i className="fas fa-exclamation overview__status__icon"></i>
                <span className="overview__status__text">
                  Contrato expirado
                  {' '}<strong className="overview__status__date-text">({getAsLocalDateString(lastContract.expires_at)})</strong>
                </span>

              </div>
            );
          }
        }
      }
    }

    if(this.props.student.physical_evaluation_exams && this.props.student.physical_evaluation_exams.length > 0) {
      let exam = null;

      for(let entry of this.props.student.physical_evaluation_exams) {
        if(!entry.completed) {
          continue;
        }

        if(exam) {
          if(entry.scheduled_date > exam.scheduled_date) {
            exam = entry;
          }
        }
        else {
          exam = entry;
        }
      }

      if(exam) {
        const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

        const date = getAsLocalDate(exam.scheduled_date);
        const timeDiff = Math.abs(today.getTime() - date.getTime());
        let daysPassed = (date < today ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

        if(daysPassed > PHYSICAL_REVALUATION_THRESHOLD) {
          statusList.push(
            <div key="overview_status_physical_revaluation" className={`overview__status${(daysPassed - PHYSICAL_REVALUATION_THRESHOLD) > PHYSICAL_REVALUATION_ALERT_RANGE ? '--inativo' : '--alerta'}`}>

              <i className="fas fa-exclamation overview__status__icon"></i>
              <span className="overview__status__text">Reavaliação física liberada</span>

            </div>
          );
        }
      }
    }

    if(this.props.student.email_is_validated === false) {
      statusList.push(
        <div key="overview_status_email_validation" className={`overview__status--inativo`}>

          <i className="fas fa-exclamation overview__status__icon"></i>
          <span className="overview__status__text">Email inválido</span>

        </div>
      );
    }
    else if(this.props.student.block_email_notification === true) {
      statusList.push(
        <div key="overview_status_email_blocked" className={`overview__status--inativo`}>

          <i className="fas fa-exclamation overview__status__icon"></i>
          <span className="overview__status__text">Aluno bloqueou email</span>

        </div>
      );
    }
    else if(this.props.student.last_notification_received_at && !this.props.student.last_notification_visualization_at) {
      const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

      const date = getAsLocalDate(this.props.student.last_notification_received_at);
      const timeDiff = Math.abs(today.getTime() - date.getTime());
      let daysPassed = (date < today ? 1 : -1)*Math.ceil(timeDiff / (1000 * 3600 * 24));

      if(daysPassed > NOTIFICATION_UNREAD_THRESHOLD) {
        statusList.push(
          <div key="overview_status_notification_unread" className={`overview__status${(daysPassed - NOTIFICATION_UNREAD_THRESHOLD) > NOTIFICATION_UNREAD_ALERT_RANGE ? '--inativo' : '--alerta'}`}>

            <i className="fas fa-exclamation overview__status__icon"></i>
            <span className="overview__status__text">Notificações não estão sendo visualizadas</span>

          </div>
        );
      }
    }

    if(this.props.student.status.toLowerCase() === 'ativo') {
      statusList.push(
        <div key="overview_status_active" className="overview__status--ativo">

          <i className="fas fa-check overview__status__icon"></i>
          <span className="overview__status__text">Ativo</span>

        </div>
      );

      if(this.state.training_data.length > 0) {
        let lastClass = '';

        for(const entry of this.state.training_data) {
          if(!lastClass || lastClass < entry.started_at) {
            lastClass = entry.started_at;
          }
        }

        if(lastClass.length > 0) {
          const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

          lastClass = getAsLocalDate(lastClass);
          const timeDiff = Math.abs(today.getTime() - lastClass.getTime());

          const daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));

          if(daysPassed > STUDENT_LAST_TRAINING_ALERT_RANGE) {
            statusList.push(
              <div key="overview_status_last_training" className={`overview__status${daysPassed > STUDENT_LAST_TRAINING_RANGE ? '--inativo' : '--alerta'}`}>

                <i className="fas fa-exclamation overview__status__icon"></i>
                <span className="overview__status__text">{`Última aula há ${daysPassed} dias`}</span>

              </div>
            );
          }
        }
      }
    }
    else {
      statusList.push(
        <div key="overview_status_inactive" className="overview__status--inativo">

          <i className="fas fa-exclamation overview__status__icon"></i>
          <span className="overview__status__text">Inativo</span>

        </div>
      );
    }

    if (this.state.access_rule === ACCESS_CONTROL_RULE_BLOCKED) {
      statusList.push(
        <div key="overview_status_inactive" className="overview__status--inativo">

          <i className="fas fa-exclamation overview__status__icon"></i>
          <span className="overview__status__text">Acesso bloqueado</span>

        </div>
      );
    }

    return statusList;
  }

  getFrequencyData() {
    let lastClass = '';
    const frequencyByDate = new Map();

    const minDate = new Date();
    minDate.setDate(minDate.getDate() - STUDENT_RECENT_TRAINING_PERIOD);

    const minDateIso = getLocalDateIsoString(minDate);

    for(const entry of this.state.training_data) {
      const date = entry.started_at.slice(0, 10);

      if(date >= minDateIso) {
        if(!frequencyByDate.has(date)) {
          frequencyByDate.set(date, 0);
        }

        frequencyByDate.set(date, frequencyByDate.get(date) + 1);
      }

      if(!lastClass || lastClass < entry.started_at) {
        lastClass = entry.started_at;
      }
    }

    lastClass = getAsLocalDate(lastClass).toLocaleDateString();

    const byDate = [];

    for(const [key, value] of frequencyByDate.entries()) {
      byDate.push(new DateBarPoint(value, getAsLocalDate(key), value));
    }

    return {
      byDate,
      lastClass
    };
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getDefaultBarWidth() {
    if(this.state.screenWidth > 1200) {
      return 58;
    }
    else if(this.state.screenWidth > 900) {
      return 50;
    }

    return 18;
  }

  onSendValidationEmail() {
    this.setState({
      sendValidationEmail: true,
      confirmInProgress: false,
      confirmFailed: false
    });
  }

  async onConfirmAction() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.sendValidationEmail) {
      const userData = {user_id: this.props.student.id};

      const emailApiUrl = setUrlParameters(routes.STUDENT_SEND_VALIDATION_EMAIL_POST_API, {
        force_delivery: true
      })

      try {
        if(await postModel(emailApiUrl, userData)) {
          this.setState({
            validationEmailSent: true
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false,
          validationEmailSent: false
        });

        return;
      }
    }

    this.setState({
      sendValidationEmail: false,
      confirmInProgress: false
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      if(this.state.sendValidationEmail) {
        return 'Enviando email';
      }

      return 'Resgatando dados sobre notificação';
    }
    else if(this.state.confirmFailed) {
      if(this.state.sendValidationEmail) {
        return 'Falha ao enviar email';
      }

      return 'Falha ao resgatar dados sobre notificação';
    }

    if(this.state.sendValidationEmail) {
      return 'Reenviar email de validação';
    }

    return 'Detalhes de notificação';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }

    if(this.state.notificationStatusData !== null) {
      return (
        <div className="overview__notifications__status-data">

          <div className="overview__notifications__status-data__row">
            <p className="overview__notifications__status-data__label">Evento:</p>
            <p className="overview__notifications__status-data__value">{this.state.notificationStatusData.event}</p>
          </div>

          {this.state.notificationStatusData.severity && (
            <div className="overview__notifications__status-data__row">
              <p className="overview__notifications__status-data__label">Severidade:</p>
              <p className="overview__notifications__status-data__value">{this.state.notificationStatusData.severity}</p>
            </div>
          )}

          {this.state.notificationStatusData.reason && (
            <div className="overview__notifications__status-data__row">
              <p className="overview__notifications__status-data__label">Motivo:</p>
              <p className="overview__notifications__status-data__value">{this.state.notificationStatusData.reason}</p>
            </div>
          )}

          {this.state.notificationStatusData['delivery-status'] && (
            <div className="overview__notifications__status-data__row">
              <p className="overview__notifications__status-data__label">Mensagem:</p>
              <p className="overview__notifications__status-data__value">{this.state.notificationStatusData['delivery-status']['message']}</p>
            </div>
          )}

        </div>
      );
    }
    else if(this.state.sendValidationEmail) {
      return 'Este email de validação de conta deve ser enviado SOMENTE se for confirmado que o email atual do aluno está ' +
             'CORRETO e que o problema atual do último envio já tenha sido RESOLVIDO.';
    }

    return null;
  }

  getConfirmartionWindowConfirmText() {
    if(this.state.sendValidationEmail) {
      return 'Enviar email';
    }

    return 'Não definido';
  }

  onCancelConfirmation() {
    this.setState({
      notificationStatusData: null,
      sendValidationEmail: false,
      confirmInProgress: false,
      confirmFailed: false,
    });
  }

  render() {
    const frequencyData = this.getFrequencyData();

    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="content-frame__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          descriptionAsElement={this.state.notificationStatusData !== null}
          confirmText={this.getConfirmartionWindowConfirmText()}
          cancelText={(this.state.confirmFailed || !this.state.sendValidationEmail) ? 'Ok' : 'Cancelar'}
          visible={this.state.notificationStatusData !== null || this.state.confirmInProgress || this.state.sendValidationEmail || this.state.confirmFailed}
          onCancel={() => this.onCancelConfirmation()}
          onConfirm={() => this.onConfirmAction()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={!this.state.sendValidationEmail || this.state.confirmFailed}
        />

        <div className="overview__data-container">

          <div className="overview__registry-data-container">

            <p className="overview__registry-data">

              <span className="overview__registry-data__label">

                <i className="fas fa-id-card overview__registry-data__label__icon"></i>
                Nome:

              </span>
              <span className="overview__registry-data__text">{this.props.student.name}</span>

            </p>

            <p className="overview__registry-data">

              <span className="overview__registry-data__label">

                <i className="fas fa-envelope overview__registry-data__label__icon"></i>
                Email:

              </span>
              <span className="overview__registry-data__text">{this.props.student.email}</span>

            </p>

            <p className="overview__registry-data">

              <span className="overview__registry-data__label">

                <i className="fas fa-phone overview__registry-data__label__icon"></i>
                Telefone:

              </span>
              <span className="overview__registry-data__text">{getPhoneText(this.props.student.phone)}</span>

            </p>

            <p className="overview__registry-data">

              <span className="overview__registry-data__label">

                <i className="fas fa-clinic-medical overview__registry-data__label__icon"></i>
                Emergência:

              </span>
              <span className="overview__registry-data__text">{this.props.student.at_emergency}</span>

            </p>

          </div>

          <div className="overview__status-data-container">

            {this.getStatus()}

          </div>

        </div>

        {this.props.userPermissionIds.includes(permissions.VIEW_USER_NOTIFICATION_PERMISSION_ID) &&
          <React.Fragment>

            <HorizontalRule />

            <DefaultSubSectionTitle
              icon={<i className="fas fa-envelope"></i>}
              text="Notificações"
            />

            {this.props.student.email_is_validated === false &&
              <div className="overview__actions-container">

                <button
                  className="overview__action-button"
                  disabled={this.state.validationEmailSent}
                  onClick={(event) => this.onSendValidationEmail()}
                >

                  <i className="fas fa-envelope"></i> {this.state.validationEmailSent ? 'Email enviado' : 'Reenviar email de validação'}

                </button>

              </div>
            }

            <ModelTable
              storageKey="user_notification_list"
              properties={this.getNotificationProperties()}
              data={this.state.notifications}
              initialOrderBy="created_at"
              initialOrderIsDecrescent={true}
            >

            </ModelTable>

          </React.Fragment>
        }

        <HorizontalRule />

        <DefaultSubSectionTitle
          icon={<i className="fas fa-history"></i>}
          text="Atividades"
        />

        <ModelTable
          storageKey="user_activity_list"
          properties={this.getActivityProperties()}
          data={this.props.student.activities}
          initialOrderBy="created_at"
          initialOrderIsDecrescent={true}
        >

        </ModelTable>

        <HorizontalRule />

        {this.state.training_data.length > 0 &&
          <React.Fragment>

            <DefaultSubSectionTitle
              icon={<i className="fas fa-calendar-alt"></i>}
              text="Presença"
            />

            <div className="overview__indicator-container">

              <div className="overview__indicator">

                <h2 className="overview__indicator__label">Última aula:</h2>
                <p className="overview__indicator__value">{frequencyData.lastClass}</p>

              </div>

            </div>

            {frequencyData.byDate.length > 0 &&
              <SimpleBarGraph
                name="Frequência"
                data={frequencyData.byDate}
                height={this.getDefaultGraphHeight()}
                barColor="#4d86ce"
                labelFormatter={(value) => value}
                dateBased={true}
                dataPointWidth={this.getDefaultBarWidth()}
              />
            }

          </React.Fragment>
        }

      </React.Fragment>
    );
  }
}

export default Overview;
